<template>
  <div class="dialog">
    <div class="dialog-header">
      <p class="dialog-title mb-0">EDIT VIDEO CATEGORY</p>
    </div>
    <div class="dialog-content"
         style="overflow-y: auto; max-height: 560px;">
      <b-container class="mt-3" fluid>
        <b-form @submit="onSubmit">
          <b-form-group label="Category Title"
                        label-for="categoryTitle"
                        :label-cols="3">
            <b-form-input id="categoryTitle"
                          type="text"
                          aria-describedby="categoryTitle-input"
                          placeholder="Enter Category Title"
                          autocomplete="off"
                          @input="$v.form.categoryTitle.$touch()"
                          v-model.trim="$v.form.categoryTitle.$model"
                          :state="!$v.form.categoryTitle.$error"
            ></b-form-input>
            <b-form-invalid-feedback id="categoryTitle-input">
              <span v-if="!$v.form.categoryTitle.required">
                Category Title is required.
              </span>
              <span v-if="!$v.form.categoryTitle.uniqueCategoryTitle &&
                      $v.form.categoryTitle.required">
                Category Title must be unique.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Premium"
                        label-for="premuimType"
                        :label-cols="3">
            <toggle-button class="large-font"
                           id="premiumType"
                           v-model="form.premiumType"
                           :width="100"
                           :height="35"
                           :labels="{checked: 'YES', unchecked: 'NO'}"
            />
          </b-form-group>

          <!-- Buttons -->
          <div class="d-flex justify-content-end">
            <b-button class="text-center"
                      type="submit"
                      variant="outline-success"
                      :disabled="$v.form.$invalid || !hasChanges">
              Submit
            </b-button>
            <b-button class="text-center ml-1"
                      variant="outline-danger"
                      @click="$emit('close')">
              Cancel
            </b-button>
          </div>
        </b-form>

        <!-- Loading -->
        <loading :active.sync="isLoading"
                 :is-full-page="true"
                 background-color="#000"
                 color="#068bcd"
                 :opacity="0.3"
                 :width="70"
                 :height="70">
        </loading>
      </b-container>
    </div>
  </div>
</template>
<script>
  import _ from 'lodash';
  import { required } from 'vuelidate/lib/validators';
  import 'vue-loading-overlay/dist/vue-loading.css';

  const Loading = () => import('vue-loading-overlay');
  
  export default {
    name  : 'EditVideoCategory',
    props : {
      data : Object,
    },
    data() {
      return {
        form : {
          categoryTitle : this.data.category_title,
          premiumType   : (this.data.premium_type === 'YES') ? true : false,
        },
        isLoading           : false,
        uniqueCategoryTitle : true,
      };
    },
    components : {
      Loading,
    },
    computed : {
      hasChanges() {
        const premiumType = (this.form.premiumType) ? 'YES' : 'NO';

        return this.form.categoryTitle !== this.data.category_title ||
          premiumType !== this.data.premium_type;
      },
      categoryTitle() {
        return this.form.categoryTitle;
      },
    },
    watch : {
      categoryTitle(value) {
        if (value && this.form.categoryTitle !== this.data.category_title)
          this.checkCategoryTitle(value);
      },
    },
    methods : {

      /**
       * Check if Category Title is unique
       */
      checkCategoryTitle : _.debounce(function(title) {
        this.$http.get('api/checkCategoryTitle', {
          params : {
            categoryTitle : title,
          },
        }).then(response => {
          this.uniqueCategoryTitle = (response.data) ? false : true;
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'failed',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      }, 500),

      /**
       * Submit Form - Edit Video Category
       * @param e
       */
      onSubmit(e) {
        e.preventDefault();
        this.isLoading = true;

        this.$http.put('api/category', {
          'categoryId'   : this.data.category_id,
          'updateFields' : {
            'category_title' : this.form.categoryTitle,
            'premium_type'   : (this.form.premiumType) ? 1 : 0,
          },
        }).then(() => {
          this.closeModal();
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : `Edited ${this.form.categoryTitle} category successfully.`,
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }).finally(() => {
          this.isLoading = false;
        });

      },

      /**
       * Close Modal
       */
      closeModal() {
        this.$parent.$emit('reload-table');
        this.$emit('close');
      },
    },
    validations : {
      form : {
        categoryTitle : {
          required,
          uniqueCategoryTitle() {
            return this.uniqueCategoryTitle;
          },
        },
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import
    "../../../../assets/scss/components/admin/category/video/edit-category";
</style>
